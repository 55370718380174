.notification {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    bottom: 0px;
    background-color:#173B65;
    box-sizing: border-box;
    padding: 25px;
    z-index: 600;
    font-family: "proxima-nova",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    opacity: 1;

    &.privacy-responded {
        transition: transform .5s ease;
        transform: translateY(101%);
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .OptBox {
        width: 100%;
        margin-top: 15px;
        padding: 5px;
        box-sizing: border-box;
        text-align: left;
    }

    .OptButton a:link, 
    .OptButton a:visited, 
    .OptButton a:active {
        font-family: "proxima-nova",sans-serif;
        font-style: normal;
        font-weight: 400;
        display: inline-block;
        width: 100px;
        padding: 10px;
        background-color: #ABABAB;
        color: #fff;
        font-size: 16px;
        text-align: center;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin: 5px;
        text-decoration: none;
    }

    .OptButton a.accept {
        background-color: #C6A02C;
    }

    .OptButton a:hover {
        background-color: #fff;
        color: #444;
    }
}
/*-------------------------------------------------
    DISPOSITION
--------------------------------------------------*/

.u-content-width {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.u-site-width {
    max-width: 1400px;
    margin: 0 auto;
}

.u-margin-buffer {
    margin-left: 5%;
    margin-right: 5%;
}

.u-input-small {
    width: 62px;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
        text-align: center;
    }

    &:-moz-placeholder { /* Firefox 18- */
        text-align: center;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        text-align: center;
    }

    &:-ms-input-placeholder {
        text-align: center;
    }
}

.u-text-small {
    font-size: $font-size-small-x2;
}

.u-relative {
    position: relative;
}

.u-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-float-right {
    float: right;
}

.u-nowrap {
    white-space: nowrap;
}

/*-------------------------------------------------
    COLOR
--------------------------------------------------*/

.u-bg-lite {
    background-color: $gray-5;
}

.u-bg-med {
    background-color: $gray-4;
}

.u-bg-dark {
    background-color: $gray-3;
}

/*-------------------------------------------------
    TYPOGRAPHY
--------------------------------------------------*/

.u-h1 {
    margin: 0;
    // using padding instead of margin so that the element causes overflow in its container
    padding: .4em 0;
    line-height: 1;

    font-family: $header-font;
    font-size: $font-size-large-x6;
    font-weight: $font-weight-normal;
    color: #fff;

    span {
        color: $gray-5;
    }

    &.u-h1--no-margin {
        margin: 0;
    }
}

.u-h2 {
    margin: 0 0 .8em 0;
    line-height: 36px;

    font-family: $header-font;
    font-size: $font-size-large-x4;
    font-weight: $font-weight-normal;

    &.u-h2--lite {
        color: $blue-3;
    }

    &.u-h2--no-margin {
        margin: 0;
    }
}

.u-h3 {
    margin: 0 0 .8em 0;
    line-height: 25px;

    font-family: $header-font;
    font-size: $font-size-large-x2;
    font-weight: $font-weight-semi-bold;

    &.u-h3--lite {
        color: $blue-3;
    }

    &.u-h3--no-margin {
        margin: 0;
    }
}

.u-h4 {
    margin: 0 0 .8em 0;
    line-height: 18px;

    font-family: $header-font;
    font-size: $font-size-large-x1;
    font-weight: $font-weight-semi-bold;
}

.u-h5 {
    margin: 0 0 .8em 0;
    line-height: 18px;

    font-family: $header-font;
    font-size: $font-size-normal;
    font-weight: $font-weight-semi-bold;
}

.u-width-restricted {
    width: 100%;
    max-width: 550px;
}

.u-underbar {
    border-bottom: 6px solid $gray-4;
    padding-bottom: 15px;
}

.u-capitalize {
    text-transform: capitalize;
}

.u-hidden {
    height: 0;
    overflow: hidden;
}

/*-------------------------------------------------
    LISTS
--------------------------------------------------*/

.flat-list {
    list-style: none;
    margin: 0;
    padding: 0;
}


/*-------------------------------------------------
    MEDIA QUERIES
--------------------------------------------------*/

@media all and (max-width: 600px) {

    .u-margin-buffer--collapse-at-mobile {
        margin-left: 0;
        margin-right: 0;
    }

}

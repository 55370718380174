table.fullColumn {
	width:511px;
}

table.fullColumn.agree{
	width:100%;
	margin-bottom:25px;
}

table.fullColumn.agree td {
	text-align: left;
}

table.fullColumn.agree td.blue{
    background-color:#173B65;
    font-size: 1.1em;
    line-height: 1.5;
}

table.fullColumn.agree td.grey{
	background-color:#878787;
	height:25px;
	vertical-align:middle;
}

table.fullColumn.agree td.small{
	width:8%;
	text-align:center;
}

table.fullColumn.agree td.large{
	width:98%;
}

table.fullColumn.agree td.blue p,
table.fullColumn.agree td.blue h4
{
	color:#fff;
}

table.fullColumn.agree td.blue a:link, table.fullColumn.agree td.blue a:visited,table.fullColumn.agree td.blue a:active{
	color:#ffffff;
	text-decoration:underline;
font-family: "proxima-nova",sans-serif;
font-weight: 400;
}
input.terms[type="checkbox"] {
    display: none;
}


input[type="checkbox"] + label {
    display: inline-block;
    border: 1px solid #000;
				background-color:#fff;
    width: 30px;
    height: 30px;
    position: relative;
}
input[type="checkbox"]:checked + label:after {
    content: '✔';
    display: inline-block;
    font-size: 1.6em;
}

table.fullColumn.agree td.grey p {
    font-family: "proxima-nova",sans-serif;
    font-weight: 400;
	color:#fff;
	font-size:25px;
	padding-top: 15px;
	margin-top: 16px;
}


table.fullColumn p,
table.twoColumn p{
font-family: "proxima-nova",sans-serif;
font-weight: 400;
}


table.fullColumn td,
table.twoColumn td{
width:250px;
padding: 8px 8px 8px 8px;
height:65px;
}

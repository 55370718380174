html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-size: $font-size-normal;
    font-family: $body-font;
    line-height: 1.3em;
    color: $blue-1;
    height: 100%;
}

h1 {
    @extend .u-h1;
}

h2 {
    @extend .u-h2;
}

h3 {
    @extend .u-h3;
}

h4 {
    @extend .u-h4;
}

h5 {
    @extend .u-h5;
}

p {
    margin: 0 0 1.2em 0;
}

a {
    color: $blue-1;
    text-decoration: none;
}

td {
    text-align: center;
}

input[type="text"],
input[type="number"],
select,
textarea {
    border: 1px solid $blue-1;
    border-radius: 5px;
    padding: 5px;
    font-family: $body-font;
    font-size: $font-size-normal;
    min-height: 46px;
}

//input[type=number]::-webkit-inner-spin-button,
//input[type=number]::-webkit-outer-spin-button {
//    -webkit-appearance: none;
//    margin: 0;
//}
//
//input[type=number] {
//    -moz-appearance:textfield;
//}
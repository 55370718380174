//-------------------------------------------------
//    BURGER
//-------------------------------------------------

.burger {
	width: 41px;
	height: 20px;
	display: block;
	background: transparent url(../images/icon-burger.png) 0 0 no-repeat;
	border: none;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 20px;
	z-index: $z-index-middle-x3;

	@media (max-width: 920px) {
		top: -70px;
		background-image: url(../images/icon-burger-yellow.png);
	}
}

//-------------------------------------------------
//    Cookie Consent Wrapper
//-------------------------------------------------
.cookie-consent-wrapper {
	display: flex;
	justify-content: flex-end;
}

.ot-sdk-show-settings {
	border-radius: 10px;
}

//-------------------------------------------------
//    BUTTON
//-------------------------------------------------

.button {
	display: block;

	padding: 13px;
	width: 100%;
	max-width: 280px;
	line-height: 1;

	border-radius: 8px;
	border: none;
	outline: none;
	cursor: pointer;

	background-color: $blue-2;
	transition: background-color $transition-fast;
	color: #fff;

	text-align: center;
	font-family: $body-font;
	font-size: $font-size-normal;
	font-weight: $font-weight-normal;

	&:hover {
		background-color: $yellow-1;
		transition: background-color $transition-fast;
	}

	&.button--inverted {
		background-color: #fff;
		color: $blue-2;

		&:hover {
			background-color: $yellow-1;
			transition: color, background-color $transition-fast;
			color: #fff;
		}
	}

	&.button--gray {
		border: 2px solid #fff;
		background-color: $gray-1;

		&:hover {
			background-color: $gray-4;
		}
	}

	&.button--autowidth {
		display: inline-block;
		width: auto;
	}

	&.button--landing {
		padding: 10px 20px;
		font-size: $font-size-large-x3;
		display: inline-block;
		width: auto;
	}

	&--green {
		border: none;
		background-color: #317079;
		font-size: 16px;
		padding: 16px;
		border-radius: 8px;
		color: #fff;
	}
}

//-------------------------------------------------
//    DATA ROW
//-------------------------------------------------
.results-container {
	// &.results-container--isfiltering {
	//     position: relative;
	//     min-height: 200px;

	//     &:after {
	//         content: '';
	//         position: absolute;
	//         top: 0;
	//         right: 0;
	//         bottom: 0;
	//         left: 0;
	//         background: transparent url(../images/tail-spin.svg) center 100px / 100px no-repeat;
	//     }

	//     .row {
	//         opacity: .5;
	//     }
	// }
}

/* autoprefixer grid: autoplace */
.data-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	cursor: pointer;
	position: relative;

	&:hover {
		.data-row__controls {
			transition: opacity $transition-normal;
			opacity: 1;
		}
	}
}

.data-row__cell {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	flex-grow: 1;
	flex-basis: 0;
	//min-width: 180px;

	&.data-row__cell--label-cell {
		padding-top: 20px;
		text-align: center;
	}
}

.data-row__label {
	display: none;
	//margin: 0 auto .6em auto;
	margin: 0 auto;
	max-width: 150px;
	min-height: 40px;
	line-height: 18px;
	// text-transform: uppercase;
	// font-size: 10px;
	// opacity: .5;
	// display: none;
}

.data-row__controls {
	transition: opacity $transition-normal;
	opacity: 0;
	text-align: left;
	padding-left: 5%;
	padding-bottom: 15px;
	width: 100%;

	body.touch-device & {
		opacity: 1;
	}
}

.data-row__control {
	cursor: pointer;
	border: none;

	font-size: $font-size-small-x2;
	background-color: $gray-1;
	border-radius: 12px;
	padding: 3px 8px;
	margin-right: 10px;
	color: #fff;

	&:hover {
		background-color: $gray-2;
		color: $blue-2;
	}
}

.data-row__header {
	.data-row__label {
		display: block;
	}

	.data-row__cell {
		//border-left: 1px solid #fff;
		//border-image: linear-gradient(#fff 0, transparent 50%) 1 100%;
		padding-bottom: 20px;

		&:first-child {
			border-left: none;
		}
	}
}

.data-row__ft {
	margin-right: 3px;
}

.data-row__in {
	margin-left: 3px;
}

.row {
	border-bottom: 1px solid #fff;

	&.row--padded {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&.row--padded-top-large {
		padding-top: 40px;
	}

	&.row--deck-specifications {
		padding-top: 40px;
		border-bottom-color: #fff;
	}

	&.row--collapsed:hover {
		cursor: pointer;
		background-color: $yellow-1;
	}
}

//-------------------------------------------------
//    DIALOG
//-------------------------------------------------

.dialog {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 575px;
	border: 2px solid #fff;
	border-radius: 8px;
	color: #fff;

	@media (max-width: 1070px) {
		padding-bottom: 20px;
	}

	li {
		line-height: 1.2;
		margin-bottom: 0.5em;
	}

	&.dialog--green {
		background-color: rgba($green-1, 0.8);

		.dialog__title {
			background-color: $green-1;
		}
	}

	&.dialog--red {
		background-color: rgba($red-1, 0.8);

		.dialog__title {
			background-color: $red-1;
		}
	}

	&.dialog--blue {
		background-color: rgba($blue-2, 0.8);

		.dialog__title {
			background-color: $blue-2;
		}
	}

	&.dialog--purple {
		background-color: rgba($purple-1, 0.8);

		.dialog__title {
			background-color: $purple-1;
		}
	}

	.dialog__title {
		font-size: $font-size-large-x5;
		padding: 25px 0 25px 40px;
		font-weight: $font-weight-lite;
		border-radius: 8px 8px 0 0;

		@media (max-width: 1140px) {
			text-align: center;
			padding-left: 0;
			font-size: $font-size-large-x4;
		}
	}

	.dialog__body-wrapper {
		@media (min-width: 1071px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.dialog__body {
		padding: 25px 40px;
		flex-grow: 1;
		line-height: 1.5;

		@media (max-width: 1070px) {
			padding: 10px 0;
			text-align: center;
		}
	}

	.dialog__actions {
		padding: 0 40px;
		text-align: center;

		@media (min-width: 1071px) {
			text-align: right;
		}
	}
}

//-------------------------------------------------
//    FILTER FORM WRAPPER
//-------------------------------------------------

.filter-form-wrapper {
	opacity: 0;
	transition: opacity 0.5s;

	&.filter-form-wrapper--ready {
		opacity: 1;
		transition: opacity 0.5s;
	}

	&--is-filtering {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: transparent url(../images/tail-spin.svg) center 100px / 100px no-repeat;
			z-index: 1000;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #fff;
			opacity: 0.1;
			z-index: 2;
		}
	}
}

//-------------------------------------------------
//    FOOTER
//-------------------------------------------------

.footer {
	padding-top: 100px;
	font-size: $font-size-small-x1;

	address {
		font-style: normal;
		margin-bottom: 30px;
	}

	.copyright {
		margin-bottom: 16px;
	}

	.privacy-policy {
		margin-bottom: 46px;

		a:not(:first-child) {
			margin-left: 35px;
		}
	}

	.tagline {
		text-align: right;
		padding: 20px 0;
		font-size: $font-size-large-x2;
		color: $blue-2;
	}

	.color-band {
		background-color: $gray-6;
		padding: 30px 0;
	}

	.footer-columns {
		display: flex;
		justify-content: space-between;
	}

	.footer-columns__column {
		width: 46%;
	}

	.footer-columns__column:last-child {
		text-align: right;
	}
}

/*-------------------------------------------------
    FORM MODE WRAPPER
--------------------------------------------------*/

.form-mode-wrapper {
	border-bottom: 1px solid $gray-4;

	.form-mode-wrapper-inner {
		position: relative;
	}

	.utility-links {
		@media (min-width: 1020px) {
			position: absolute;
			right: 0;
			bottom: 20px;
		}
		text-align: right;

		.button {
			display: inline-block;
			margin-left: 5px;
			width: auto;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}

//-------------------------------------------------
//    GLOBAL NAV
//-------------------------------------------------

.global-nav {
	position: absolute;
	top: 0;
	right: -10px;
	min-width: 240px;

	// @media (min-width: 601px) and (max-width: 920px) {
	//     top: -80px;
	// }

	// hide by default
	height: 0;
	overflow: hidden;

	padding-left: 50px;
	box-sizing: border-box;
	background-color: rgba($gray-2, 0.74);
	z-index: $z-index-middle-x1;

	transition: padding $transition-normal;

	ul {
		@extend .flat-list;
	}

	li {
		margin-bottom: 0.6em;
	}

	a {
		color: #fff;
	}

	a:hover {
		opacity: 0.8;
	}

	body.burger-expanded & {
		height: auto;
		padding-top: 20px;
		min-height: 142px;
		transition: padding $transition-normal;
	}

	&--mobile {
		display: none;
	}
}

//-------------------------------------------------
//    Instructional text
//-------------------------------------------------

.instructionalText {
	display: block;
	margin-top: 6px;
}

//-------------------------------------------------
//    LANDING
//-------------------------------------------------

.landing {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
	margin-bottom: 60px;
	background-color: #b5bcc4;
	padding: 35px 0 0;
}

.landing__panel {
	background: transparent center center / cover no-repeat;
	width: 50%;
	display: flex;
	padding: 0 20px 20px 20px;
	margin-bottom: 20px;
	box-sizing: border-box;

	&.landing__panel--decktools {
		justify-content: flex-end;
		//background-image: url(../images/bg-landing-panel-deck-tools.jpg);
	}

	&.landing__panel--joisttools {
		justify-content: flex-start;
		//background-image: url(../images/bg-landing-panel-joist-tools.jpg );
	}
}

//-------------------------------------------------
//    LOADING SPINNER
//-------------------------------------------------

.loading-spinner {
	position: absolute;
	width: 100%;

	img {
		display: block;
		margin: 0 auto;
		transform: translateY(80px);
		z-index: 0;
	}

	body.ready & {
		opacity: 0;
	}
}

//-------------------------------------------------
//    MAIN
//-------------------------------------------------

.main {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

//-------------------------------------------------
//    MAIN CONTENT
//-------------------------------------------------

.main-content {
	flex-grow: 1;
}

//-------------------------------------------------
//    MASTHEAD
//-------------------------------------------------

.masthead {
	padding-top: 24px;
	margin-bottom: 40px;
}

.masthead__top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	.tab a {
		color: #fff;
	}
}

.masthead__logo {
	margin-bottom: 14px;

	img {
		display: block;
	}
}

.masthead__banner {
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&.masthead__banner--decktools {
		background-image: url(../images/bg-deck-tools-banner.jpg);
	}

	&.masthead__banner--designtools {
		background-image: url(../images/bg-design-tools-banner.jpg);
	}
}

.masthead__tab-strip {
	ul {
		@extend .flat-list;
		display: flex;
		flex-direction: row;
	}

	li {
		margin: 0;
		padding: 0;
		width: 33.333%;
		box-sizing: border-box;
	}

	a {
		text-align: center;
		color: #fff;
		padding-top: 17px;
		padding-bottom: 17px;
		white-space: nowrap;
	}

	.tab--active {
		background-color: $yellow-1;
	}
}

.section-title {
	padding-top: 30px;
}

//-------------------------------------------------
//    META FILTER
//-------------------------------------------------

.meta-filters {
	@media all and (min-width: 601px) {
		.toggle,
		.select-wrapper,
		.meta-filter {
			padding: 0 1vw;

			// &:first-child {
			//     padding-left: 0;
			// }

			// &:last-child {
			//     padding-right: 0;
			// }
		}
	}

	.meta-filter__label {
		display: block;
		text-align: center;
	}

	.meta-filter__label {
		//margin-bottom: .3em;
		text-align: center;
		max-width: 240px;

		@media (min-width: 601px) {
			min-height: 40px;
		}
	}

	@media (min-width: 1260px) {
		select {
			min-width: 320px;
		}
	}

	.select {
		width: 100%;
		max-width: 240px;
	}

	.toggle {
		// min-width: 300px;

		//.filter-form-wrapper--Roof_Deck & {
		// min-width: 330px;
		//}
	}
}

//-------------------------------------------------
//    NO-RESULTS
//-------------------------------------------------

.no-results {
	text-align: center;
	background: #bea263;
	color: #fff;
	font-size: 20px;
	padding-top: 40px;
	padding-bottom: 40px;
}

//-------------------------------------------------
//    META FILTERS
//-------------------------------------------------

.meta-filters {
	// obscure hte loading spinner
	position: relative;
	z-index: $z-index-bottom-x1;
}

.meta-filters__toggles {
	//flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	min-height: 75px;

	@media (min-width: 601px) and (max-width: 800px) {
		.filter-form-wrapper--Form_Deck & {
			.toggle:nth-child(odd),
			.select-wrapper:nth-child(odd) {
				margin-right: 1vw;
			}

			.toggle {
				margin: 0 0 15px 0;
			}

			.meta-filter__label,
			.toggle__title {
				max-width: 300px;
				margin: 0 auto;
			}

			.toggle__items {
				max-width: 250px;
				margin: 0 auto;
			}

			// .select {
			//     max-width: 200px;
			//     margin: 0 auto;
			// }
		}
	}
}

//-------------------------------------------------
//    PANELS
//-------------------------------------------------

.panels {
	padding-top: 60px; // make room from the close x
}

.panel {
	background-color: #fff;
	padding: 30px 40px;
	display: none;

	&.panel--visible {
		display: block;
	}
}

.panel__header {
	.button {
		// prevent a collision with the adjacent header
		margin-left: 20px;
		transform: translateY(-10px);
	}
}

.panel__content-scroller {
	overflow: auto;
}

.panel__content {
	&.panel__content--min-width {
		min-width: 980px;
	}
}

//-------------------------------------------------
//    ROW DETAIL
//-------------------------------------------------

.row-detail {
	grid-column: 1 / span 6;
	grid-row: 3;
	height: auto;
	padding-top: 12px;
	padding-bottom: 40px;
	position: relative;

	.row-detail__close {
		position: absolute;
		top: 20px;
		right: 0;
		width: 36px;
		height: 35px;

		padding: 0;
		font-size: $font-size-large-x4;
	}
}

//-------------------------------------------------
//    SELECT
//-------------------------------------------------

.select {
	position: relative;
	height: 47.5px;
	border-radius: 8px;
	overflow: hidden;
	width: 90%;
	max-width: 200px;

	@media (min-width: 601px) {
		margin: 0 auto;
		min-height: 40px;
	}

	background-color: $blue-2;
	border: 2px solid $blue-2;
	color: #fff;

	&.hover,
	&:hover {
		background-color: $yellow-1;

		select {
			background-color: $yellow-1 !important;
			color: #fff;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 0;
		height: 0;
		right: 10px;
		bottom: 0;
		margin: auto;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: #fff transparent transparent transparent;
		pointer-events: none;
	}

	//&:hover,
	//&:active {
	//    background-color: $yellow-1;
	//    color: #fff;
	//}

	select {
		font-size: $font-size-normal;
		box-shadow: none;
		background: transparent;
		color: #fff;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		border: none;

		cursor: pointer;
		outline: none;
		padding-left: 8px;
		padding-right: 15px;

		// Disable default styling on ff
		-moz-appearance: none;

		// Disable ugly ass outline on firefox
		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		// Disable default styling on webkit browsers
		-webkit-appearance: none;

		// Disable default arrow on IE 11+
		&::-ms-expand {
			display: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #fff; /* your normal text color here */
		}

		&:-moz-focusring * {
			color: #fff; /* your normal text color here */
			text-shadow: none;
		}

		&:focus,
		&:active {
			background-color: $blue-2;
			color: #fff;
		}

		&:disabled {
			opacity: 0.5;
		}
	}
}

//-------------------------------------------------
//    TABS
//-------------------------------------------------

.tabs {
	display: flex;
	@extend .flat-list;
}

.tab {
	border-radius: 8px 8px 0 0;
	background-color: $gray-1;
	transition: background-color $transition-fast;
	color: #fff;
	font-family: $header-font;
	font-size: $font-size-normal;
	margin-left: 3px;
	opacity: 1;

	a,
	button {
		font-size: $font-size-normal;
		font-weight: $font-size-normal;
		border: none;
		background: transparent;
		display: block;
		padding: 17px 40px;
		outline: none;
		cursor: pointer;
	}

	&:first-child {
		margin-left: 0;
	}

	&:hover {
		background-color: $yellow-1;
		transition: background-color $transition-fast;
	}

	&.tab--dark-text {
		color: $blue-1;
	}

	&.tab--compact a {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.tabs--bordered .tab {
	border: 2px solid #fff;
	border-left-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 0;
	margin-left: 0;

	&:first-child {
		border-left-width: 2px;
	}

	&:last-child {
		border-right-width: 2px;
	}
}

.tabs--simple {
	.tab {
		background-color: #fff;
		transition: background-color $transition-fast;
		color: $blue-1;

		&:hover {
			background-color: $yellow-1;
			transition: background-color $transition-fast;

			a,
			button {
				color: #fff;
			}
		}
	}

	.tab.tab--active {
		background-color: $gray-4;

		&:hover {
			a,
			button {
				color: $blue-1;
			}
		}
	}
}

.tools-overview {
	font-family: 'proxima-nova', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 1.13em;
	line-height: 1.65em;
	color: #666;
	padding-bottom: 15px;
}

//-------------------------------------------------
//    TOGGLE ( https://codepen.io/pamgriffith/pen/zcntm/ )
//-------------------------------------------------

.toggle {
	//width: 30%;
	min-width: 200px;

	.toggle__title {
		text-align: center;
		//margin-bottom: .3em;

		@media (min-width: 601px) {
			min-height: 40px;
		}
	}

	.toggle__items {
		display: flex;
		flex-direction: row;
		max-width: 300px;
		margin: 0 auto;
	}

	input[type='radio'] {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.toggle__item {
		width: 50%;
	}

	.toggle__item:first-child label {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.toggle__item:last-child label {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	// .toggle__item input:focus ~ .toggle__label {
	//     background-color: $yellow-1;
	// }
}

.toggle__label {
	display: block;
	padding: 14px 13px;
	width: 100%;
	box-sizing: border-box;

	background-color: $gray-2;
	border-style: solid;
	border-width: 2px;
	border-color: $gray-2;
	white-space: nowrap;

	cursor: pointer;
	text-align: center;

	&.toggle__label--selected {
		color: white;
		border-color: #fff;
		background-color: $blue-2;
	}
}

//-------------------------------------------------
//    MEDIA QUERIES
//-------------------------------------------------

// -- 601 >

@media all and (max-width: 1300px) {
	body.burger-expanded {
		.global-nav {
			min-height: 132px;
			border-radius: 0 0 8px 8px;
		}
	}
}

// -- 1225
@media all and (max-width: 1225px) {
	.burger {
		right: 10px;
	}

	.global-nav {
		right: 0;
	}
}

// -- 1180
@media all and (max-width: 1180px) {
	.meta-filters__toggles {
		font-size: $font-size-small-x1;
	}

	.toggle {
		flex-grow: 1;
		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.toggle label {
		width: auto;
		// padding: 6px 14px;
	}
}

// -- 900
@media all and (max-width: 900px) {
	.toggle {
		width: auto;
	}
}

// -- 800
@media (max-width: 800px) {
	body.burger-expanded {
		.global-nav {
			min-height: 0;
		}
	}

	.masthead .u-h1 {
		font-size: 10vw;
	}

	// .dialog {
	//     .dialog__title {
	//         font-size: 6.25vw;
	//         padding: 3.1vw 0 3.1vw 5vw;
	//     }
	// }

	.meta-filters {
		select {
			min-width: 180px;
		}
	}

	// Composite Deck: Load Table responsive
	//.filter-form-wrapper--Composite_Deck {
	.filter-form-wrapper {
		.meta-filters__toggles {
			flex-direction: column;

			.toggle {
				margin: 0 0 15px 0;
				padding: 0;

				.toggle__title {
					min-height: 0;
					margin-bottom: 5px;
				}
			}
		}

		.meta-filter {
			padding-bottom: 20px;
		}

		.meta-filters {
			.meta-filter__label {
				min-height: 0;
				margin: 0 auto;
				margin-bottom: 5px;
			}

			.select-wrapper {
				padding: 0;
				margin-bottom: 15px;
			}
		}
		.data-row--inputs {
			flex-direction: column;

			.select {
				max-width: 240px;
			}

			.data-row__label {
				font-size: 14px;
				min-height: 0;
				max-width: none;
				margin-bottom: 5px;
			}

			.toggle__title {
				margin-bottom: 5px;
			}

			.instructionalText {
				font-size: 12px;
				font-style: italic;
			}

			.data-row__cell:not(:last-child) {
				padding-bottom: 0;
			}
		}

		.toggle__title {
			min-height: 0;
			font-size: 14px;
		}

		.toggle__items {
			max-width: 250px;
			width: 100%;
		}
	}
}

// -- 750
@media all and (max-width: 750px) {
	.diaphragm-design-row {
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 'col1 col2';
		-ms-grid-rows: auto auto auto auto;
		grid-template-rows: auto auto auto auto;
	}

	.diaphragm-design-row__cell:nth-child(1) {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 1;
		grid-row: 1;
	}

	.diaphragm-design-row__cell:nth-child(2) {
		-ms-grid-column: 2;
		grid-column: 2;
		-ms-grid-row: 1;
		grid-row: 1;
	}

	.diaphragm-design-row__cell:nth-child(3) {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;
	}

	.diaphragm-design-row__cell:nth-child(4) {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;
	}
}

// -- 720
@media all and (max-width: 720px) {
	.panel__header {
		.button {
			width: auto;
		}
	}

	.button {
		padding: 9px;
	}

	.form-title {
		display: none;
	}

	.utility-links {
		margin-bottom: 15px;

		button,
		a {
			min-width: 160px;
		}
	}
}

// -- 700
@media all and (max-width: 700px) {
	.dialog {
		.dialog__title {
			font-size: $font-size-large-x2;
		}
	}
	// .dialog {
	//     .dialog__title {
	//         font-size: 5vw;
	//         padding-left: 3vw;
	//     }

	//     .dialog__body {
	//         padding-left: 3vw;
	//         padding-right: 3vw;
	//     }

	//     .dialog__actions {
	//         text-align: center;
	//         padding: 0;
	//     }
	// }
}

@media (max-width: 660px) {
	.meta-filters__toggles {
		flex-direction: column;
	}

	.meta-filters {
		.meta-filter {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 15px;
		}

		.meta-filter__label {
			display: block;
			margin: 0 auto;
			padding: 0;
			min-height: 0;
		}
	}

	.data-row--inputs {
		flex-direction: column;

		.data-row__cell {
			&:not(:last-child) {
				padding-bottom: 0;
			}
		}

		.data-row__label {
			opacity: 1;
			min-height: 0;
		}

		.select {
			max-width: 240px;
		}
	}
}

// -- 680
@media all and (min-width: 600px) and (max-width: 680px) {
	.select {
		&:after {
			right: 4px;
		}
	}
}

// -- 600
@media all and (max-width: 600px) {
	body {
		font-size: 14px;
	}

	.burger {
		top: -120px;
	}

	.masthead__top {
		padding-top: 30px;
	}

	.global-nav--desktop {
		display: none;
	}

	.global-nav {
		&.global-nav--desktop {
			display: none;
		}

		&.global-nav--mobile {
			display: block;
			height: 0;
			overflow: hidden;
		}
	}

	body.burger-expanded {
		.global-nav.global-nav--mobile {
			height: auto;
			top: auto;
			left: 0;
			right: 0;
			background-color: $gray-2;
			border-radius: 0;
			padding-top: 0;

			a {
				border-bottom: 1px solid #fff;
				display: block;
				padding-bottom: 10px;
				transition: padding-bottom $transition-normal;
			}
		}
	}

	.select {
		margin-left: auto;
		margin-right: auto;
	}

	// data row
	.data-row {
		//grid-template-columns: repeat(3, [col] 1fr);
		//grid-template-rows: repeat(3, [row] auto);

		-ms-grid-columns: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 'col1 col2 col3';

		&.data-row__header {
			display: block;

			.data-row__label {
				height: auto;
				min-height: 0;
			}
		}

		.data-row__controls {
			opacity: 1;
			background-color: $gray-2;
			grid-row: 3;
			grid-column: 1 / span 3;

			display: flex;
			flex-direction: row;
			justify-content: space-around;
			padding: 15px;
		}
	}

	.data-row__cell:nth-child(1) {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 1;
		grid-row: 1;
	}
	.data-row__cell:nth-child(2) {
		-ms-grid-column: 2;
		grid-column: 2;
		-ms-grid-row: 1;
		grid-row: 1;
	}
	.data-row__cell:nth-child(3) {
		-ms-grid-column: 3;
		grid-column: 3;
		-ms-grid-row: 1;
		grid-row: 1;
	}
	.data-row__cell:nth-child(4) {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;
	}
	.data-row__cell:nth-child(5) {
		-ms-grid-column: 2;
		grid-column: 2;
		-ms-grid-row: 2;
		grid-row: 2;
	}
	.data-row__cell:nth-child(6) {
		-ms-grid-column: 3;
		grid-column: 3;
		-ms-grid-row: 2;
		grid-row: 2;
	}

	.data-row__controls {
		-ms-grid-row: 3;
		grid-row: 3;
		-ms-grid-column: 1;
		-ms-grid-column-span: 3;
		grid-column: 1 / span 3;
	}

	//.data-row__mobile-display {
	.data-row__label {
		display: block;
		height: 40px;
		font-size: $font-size-small-x1;
		line-height: 1;
		opacity: 0.5;
	}

	.data-row__desktop-display {
		display: none;
	}

	// footer
	.footer {
		address,
		.copyright,
		.privacy-policy {
			margin-bottom: 1.2em;
		}

		.footer-columns {
			display: block;
		}

		.footer-columns__column {
			width: auto;

			&:last-child {
				text-align: left;
			}
		}
	}

	.global-nav {
		padding-left: 0;

		li {
			margin-bottom: 0;
		}

		a {
			padding: 10px 0 0 5%;
			transition: padding-bottom $transition-normal;
		}
	}

	.landing {
		display: block;
		margin-bottom: 40px;
	}

	.landing__panel {
		width: 100%;
		display: block;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.masthead__top {
		display: block; // disable flex now
	}

	.masthead__logo img {
		margin-left: auto;
		margin-right: auto;
		width: 55.5vw;
	}

	.masthead__top .tabs {
		width: 100%;

		.tab {
			width: 100%;
			text-align: center;
		}
	}

	.masthead__tab-strip a {
		font-size: 2.65vw;
		padding-top: 3.1vw;
		padding-bottom: 3.1vw;
	}

	.meta-filters__toggles {
		flex-direction: column;

		.toggle {
			flex-direction: column;
			//align-items: flex-start;
			width: auto;
			margin-bottom: 6px;
			margin-left: 0;
		}

		.toggle__items {
			width: 100%;
			//max-width: 360px;
		}

		.toggle__item {
			width: 50%;
		}

		.toggle__title {
			text-align: left;
		}

		label {
			width: 100%;
		}
	}

	.data-row--inputs {
		.toggle {
			flex-direction: column;
			width: auto;
			margin-bottom: 6px;
			margin-left: 0;
		}
	}

	.panel {
		padding-left: 20px;
		padding-right: 20px;
	}

	.row {
		margin-bottom: 6px;
		border-bottom: 1px solid $gray-4;
	}

	.row-detail {
		padding-bottom: 0;

		.row-detail__close {
			right: 20px;
		}
	}

	.tab {
		a {
			padding-left: 20px;
			padding-right: 20px;
		}

		button {
			font-size: 14px;
		}
	}

	// toggle
	.toggle {
		display: flex;
		width: auto;
		align-items: center;

		.toggle__title {
			min-width: 140px;
			margin-right: 10px;
		}
	}
}

// -- 500
@media all and (max-width: 500px) {
	.panel__header {
		.button {
			margin-left: 0;
			float: none;
		}
	}

	.row-detail {
		.row-detail__close {
			top: 77px;
		}
	}
}

@media all and (max-width: 480px) {
	.masthead__logo {
		img {
			width: 255px; // don't scale below this threshold
			// margin-left: 0;
			// margin-right: 0;
		}
	}
}

@media all and (max-width: 386px) {
	.form-mode-wrapper {
		.utility-links {
			button,
			a {
				width: 180px;
				box-sizing: border-box;
				display: block;
				margin-bottom: 8px;
			}
		}
	}
}

// -------------------------------------------------
//    COLOR
// --------------------------------------------------

$gray-1: #8f959b;
$gray-2: #a1a9b1;
$gray-3: #a1a9b1;
$gray-4: #b5bcc4;
$gray-5: #dfdfdf;
$gray-6: #cccfd2;

$yellow-1: #bea263;
$yellow-2: #dab379;

$blue-1: #08172b;
$blue-2: #2d4660;
$blue-3: #566a7f;


$green-1: #1e5461;
$green-2: #223236;

$red-1: #6a4234;

$purple-1: #523944;
$purple-2: #604b56;

//-------------------------------------------------
//    FONTS
//--------------------------------------------------

$header-font: "century-gothic", sans-serif;
$body-font: "proxima-nova", sans-serif;

$font-size-large-x6: 80px;
$font-size-large-x5: 50px;
$font-size-large-x4: 32px;
$font-size-large-x3: 22px;
$font-size-large-x2: 20px;
$font-size-large-x1: 18px;
$font-size-normal: 16px;
$font-size-small-x1: 14px;
$font-size-small-x2: 12px;

$font-weight-lite: 100;
$font-weight-normal: 300;
$font-weight-semi-bold: 400;
$font-weight-bold: 600;

// stacking
$z-index-top-x3: 102;
$z-index-top-x2: 101;
$z-index-top-x1: 100;

$z-index-middle-x3: 52;
$z-index-middle-x2: 51;
$z-index-middle-x1: 50;

$z-index-bottom-x3: 3;
$z-index-bottom-x2: 2;
$z-index-bottom-x1: 1;


// transitions
$transition-fast: .2s;
$transition-normal: .5s;